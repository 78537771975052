import React, { useState } from "react";
import { Typography, Button } from "@mui/material";

import BackgroundVideo from "../../assets/videos/vidBsckground.mp4";

import {
  LoginWrapper,
  StyledVideoBackground,
  StyledVideoOverlay,
  StyledLoginContent,
  ImageWrapper,
  InputsWrapper,
  StyledForm,
  StyledImage,
} from "./LoginStyles";
import LoginImage from "../../assets/images/loginImage.svg";
import CustomInput from "../../components/inputComponent/inputComponent";
import { verifyAllForm } from "../../utility/helpers";
import { useAppDispatch } from "../../app/hooks";
import { loginAsync } from "../../features/auth/asyncAuthActions";

export const LoginPage = (): React.ReactElement => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [showErrors, setShowErrors] = useState(false);
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    if (
      verifyAllForm([
        {
          verification: "email",
          value: values.email,
        },
        {
          verification: "required",
          value: values.password,
        },
      ])
    ) {
      dispatch(loginAsync({ email: values.email, password: values.password }));
    } else {
      setShowErrors(true);
    }
  };

  return (
    <LoginWrapper>
      {/* <Toolbar /> */}
      <StyledVideoBackground loop muted autoPlay>
        <source src={BackgroundVideo}></source>
      </StyledVideoBackground>
      <StyledVideoOverlay />
      <StyledLoginContent elevation={2}>
        <ImageWrapper>
          <StyledImage src={LoginImage} alt="Login" />
        </ImageWrapper>
        <StyledForm
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            color="primary"
            textAlign="center"
          >
            تسجيل الدخول
          </Typography>
          <InputsWrapper>
            <CustomInput
              inputType="email"
              StartAdornment="email"
              handleInputChange={(e) =>
                setValues((prev) => ({ ...prev, email: e }))
              }
              value={values.email}
              uniqueIdentifier="login email"
              labelName="البريد الإلكترونى"
              showErrors={showErrors}
              verification="email"
              placeholder="إدخل بريدك الإلكترونى"
            />
            <CustomInput
              inputType="password"
              StartAdornment="password"
              handleInputChange={(e) =>
                setValues((prev) => ({ ...prev, password: e }))
              }
              value={values.password}
              uniqueIdentifier="password-inputs"
              showPassword={values.showPassword}
              handleShowPass={() =>
                setValues((prev) => ({
                  ...prev,
                  showPassword: !prev.showPassword,
                }))
              }
              labelName="كلمة المرور"
              showErrors={showErrors}
              verification="required"
              placeholder="إدخل كلمة المرور"
            />
          </InputsWrapper>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            sx={{
              marginTop: "2rem",
              padding: "0.6rem",
              fontWeight: "bold",
              fontSize: "1rem",
            }}
          >
            تسجيل الدخول
          </Button>
        </StyledForm>
      </StyledLoginContent>
    </LoginWrapper>
  );
};
