import { styled } from "@mui/material/styles";
import { Typography, TextField } from '@mui/material';

export const StyledTitelsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center'
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
}));

export const StyledDateWrapper = styled('div')(({ theme }) => ({
    width: '30%',
    display: 'flex',
    alignItems: 'center'
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.purple,
    width: '40%',
    display: 'flex',
    alginItems: 'center'
}));

export const StyledFilterationInputsWrapper = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '48% 48%',
    gridGap: '1rem',
    [theme.breakpoints.down('md')]: {
        display: 'grid',
        width: '70%',
        alignItems: 'center',
        gridGap: '1rem',
        gridTemplateColumns: '90%',
    }
}))