import { styled } from "@mui/material/styles";
import { Typography, TextField } from '@mui/material';

export const StyledTitelsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center'
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
}));

export const StyledDateWrapper = styled('div')(({ theme }) => ({
    width: '30%',
    display: 'flex',
    alignItems: 'center'
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.purple,
    width: '40%',
    display: 'flex',
    alginItems: 'center'
}));