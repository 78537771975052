import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { GetAdornment } from '../AdornmentHash';
import { Adornments } from '../../types/Adornments';
import { handleVerify } from '../../utility/helpers';
import { FormVerification } from '../../types/FormVerification';
import FormHelperText from '@mui/material/FormHelperText';
import {
  StyledFormController,
  StyledIconWrapper,
  StyledLabelTypography,
  StyledOutlinedInput,
} from './StyledInputComponent';

interface CustomInputProps {
  inputType: 'text' | 'password' | 'number' | 'email';
  StartAdornment?: Adornments;
  EndAdornment?: Adornments;
  handleInputChange: (e: string) => void;
  value: string | number;
  // it needs to be unique per page
  uniqueIdentifier: string;
  showPassword?: boolean;
  labelName: string;
  handleShowPass?: () => void;
  showErrors: boolean;
  verification: FormVerification;
  secondValue?: string;
  showLabel?: boolean;
  placeholder?: string;
}

const CustomInput = ({
  inputType,
  StartAdornment,
  handleInputChange,
  value,
  uniqueIdentifier,
  showPassword,
  labelName,
  EndAdornment,
  handleShowPass,
  showErrors,
  verification,
  secondValue,
  showLabel = true,
  placeholder
}: CustomInputProps): React.ReactElement => {
  const handleClickShowPassword = () => {
    if (handleShowPass) handleShowPass();
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <div style={{ width: '100%' }}>
        {inputType === 'password' ? (
          <Box>
            {
              showLabel && (
                <StyledLabelTypography
                    variant="subtitle1"
                    textAlign="start"
                    marginBottom={1}
                    sx={(theme) => ({ color: theme.palette.common.purple })}
                  >
                    {labelName}
                  </StyledLabelTypography>
              )
            }
            <StyledFormController
              sx={{ padding: 0 }}
              variant="outlined"
              fullWidth
              error={
                Boolean(handleVerify({ verification, value, secondValue })) &&
                showErrors
              }
            >
              <StyledOutlinedInput
                id={uniqueIdentifier}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                value={value}
                sx={{ backgroundColor: '#FFFFFF', padding: 0 }}
                onChange={(e) => handleInputChange(e.target.value)}
                startAdornment={StartAdornment && GetAdornment(StartAdornment)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <StyledIconWrapper
                          Icon={VisibilityOff}
                          sx={{ margin: '0 auto' }}
                        />
                      ) : (
                        <StyledIconWrapper
                          Icon={Visibility}
                          sx={{ margin: '0 auto' }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder={placeholder??''}
              />
              <FormHelperText>
                {' '}
                {showErrors &&
                  handleVerify({
                    verification,
                    value,
                    secondValue,
                  })}{' '}
              </FormHelperText>
            </StyledFormController>
          </Box>
        ) : (
          <Box>
            {
              showLabel && (
                  <StyledLabelTypography
                    variant="subtitle1"
                    textAlign="start"
                    marginBottom={1}
                    sx={(theme) => ({ color: theme.palette.common.purple })}
                  >
                    {labelName}
                  </StyledLabelTypography>
              )
            }
            
            <StyledFormController
              sx={{ padding: 0, width: '100%' }}
              fullWidth
              variant="outlined"
              error={
                Boolean(handleVerify({ verification, value })) && showErrors
              }
            >

              <StyledOutlinedInput
                // label={labelName}
                id={uniqueIdentifier}
                fullWidth
                type={inputType}
                startAdornment={StartAdornment && GetAdornment(StartAdornment)}
                endAdornment={EndAdornment && GetAdornment(EndAdornment)}
                onChange={(e) => handleInputChange(e.target.value)}
                value={value}
                sx={{ backgroundColor: '#FFFFFF' }}
                placeholder={placeholder??''}
              />
              <FormHelperText>
                {' '}
                {showErrors &&
                  handleVerify({
                    verification,
                    value,
                  })}{' '}
              </FormHelperText>
            </StyledFormController>
          </Box>
        )}
      </div>
    </Box>
  );
};

export default CustomInput;
