import { styled } from "@mui/material/styles";
import { CSVLink } from "react-csv";

export const StyledCSVLink = styled(CSVLink)(({ theme }) => ({
  textDecoration: "none",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.secondary,
  // padding: theme.spacing(2),
  display: 'grid',
  borderRadius: '70%',
  width: '50px',
  height: '50px',
  alignItems: 'center',
  justifyContent: 'center',
}));
