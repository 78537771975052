import { Toolbar } from "@mui/material";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import { TableComponent } from "../../components/dataGridComponent/dataGridComponent";
import { LoaderComponent } from "../../components/loaderComponent/loaderComponent";
import { StyedPageWrapper } from "../../components/shareStyles";
import { selectUserAuth } from "../../features/auth/authSlice";
import {
  approveClientAsync,
  fetchUsersAsync,
} from "../../features/servants/asyncServantsActions";
import {
  handleDeleteServant,
  handleSetServantsPages,
  selectServantPageNumber,
  selectServants,
  selectServantsLimitNumber,
  selectServantsPageCount,
  selectServantsStatus,
} from "../../features/servants/servantsSlice";
import {
  StyledFilterationContainer,
  StyledPageTitle,
} from "../HomePage/HomePageStyles";
import { StyledTitelsWrapper } from "../SoulNote/SoulNoteStyles";
import { Columns } from "./NotAllowedColumns";
import { deleteClientsAsync } from "../../features/clients/asyncClientsActions";

export const NotAllowedUsers = (): React.ReactElement => {
  const dispatch = useAppDispatch();

  const clients = useAppSelector(selectServants);
  const clientsStatus = useAppSelector(selectServantsStatus);
  const limit = useAppSelector(selectServantsLimitNumber);
  const page = useAppSelector(selectServantPageNumber);
  const pageCount = useAppSelector(selectServantsPageCount);
  const userAuth = useAppSelector(selectUserAuth);

  useEffect(() => {
    dispatch(
      fetchUsersAsync({
        accessToken: userAuth?.accessToken,
        page,
        limit,
        allowed: false,
        roles: "CLIENT",
      })
    );
  }, [dispatch, limit, page, userAuth?.accessToken]);

  const handleApprove = (id: string) => {
    dispatch(
      approveClientAsync({ ids: [id], accessToken: userAuth.accessToken })
    );
    const filterd = [...clients].filter((c) => c.id !== id);
    dispatch(handleDeleteServant(filterd));
  };

  const handleDelete = (id: string) => {
    dispatch(deleteClientsAsync({ id, accessToken: userAuth.accessToken }));
    const filterd = [...clients].filter((c) => c.id !== id);
    dispatch(handleDeleteServant(filterd));
  };

  return (
    <StyedPageWrapper>
      <Toolbar />
      <LoaderComponent open={clientsStatus === "loading"} />
      <StyledFilterationContainer
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          margin: "1rem",
          [theme.breakpoints.down("md")]: { margin: 0 },
        })}
      >
        <StyledTitelsWrapper>
          <StyledPageTitle variant="h5">
            {" "}
            مخدومين في انتظار الموافقة{" "}
          </StyledPageTitle>
        </StyledTitelsWrapper>
      </StyledFilterationContainer>
      <TableComponent
        columns={Columns({ handleApprove, handleDelete })}
        rows={clients}
        isWithPagination
        limit={limit}
        page={page}
        pageCount={pageCount}
        onPageChange={(pageNumber) => {
          dispatch(handleSetServantsPages(pageNumber));
        }}
      />
    </StyedPageWrapper>
  );
};
