import { styled } from "@mui/material/styles";
import { sideDrawerWidth } from "../utility/constants";

export const StyedPageWrapper = styled('div')(({ theme }) => ({
    marginRight: `${sideDrawerWidth}px`,
    width: `calc(100% - ${sideDrawerWidth}px)`,
    padding: '1rem',
    [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        width: '100%'
    }
}))
