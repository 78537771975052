export const headers = [
  {
    label: "الاسم",
    key: "name"
  }, 
  {
    label: "الصلاة (باكر)",
    key: "earlyPray",
  }, {
    label: "الصلاة (غروب)",
    key: "sunsetPray",
  }, {
    label: "الصلاة (النوم)",
    key: "sleepPray"
  }, 
  {
    label: "الكتاب المقدس",
    key: "bible"
  }, 
  {
    label: "التناول",
    key: "eating",
  },
  {
    label: "الاعتراف",
    key: "confession",
  }
]