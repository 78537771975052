import { GridColDef } from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from "moment";

export interface ColumnProps {
  handleRedirect: (id: string) => void;
  handleDelete: (id: string) => void;
}

export const Columns = ({
  handleRedirect,
  handleDelete,
}: ColumnProps): GridColDef[] => [
  {
    field: "name",
    headerName: "الاسم",
    sortable: false,
    filterable: false,
    width: 240,
    renderCell: (params: any) => {
      return (
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
            color: (theme) => theme.palette.common.purple,
          }}
        >
          {params.row.name}
        </Typography>
      );
    },
  },
  {
    field: "DOB",
    sortable: false,
    filterable: false,
    headerName: "تاريخ الميلاد",
    width: 100,
    renderCell: (params: any) => {
      return (
        <Typography variant="body1">
          {moment(params.row.DOB).format("DD-MM-YYYY")}
        </Typography>
      );
    },
  },
  {
    field: "educationalLevelOrWork",
    headerName: "المرحلة الدراسية أو العمل",
    sortable: false,
    filterable: false,
    width: 250,
  },
  {
    field: "church",
    headerName: "الكنيسة",
    filterable: false,
    sortable: false,
    renderCell: (params: any) => {
      return <Typography variant="body1">{params.row.church}</Typography>;
    },
    width: 120,
  },
  {
    field: "action",
    headerName: "مسح",
    sortable: false,
    filterable: false,
    renderCell: (params: any) => {
      return (
        <IconButton color="error" onClick={() => handleDelete(params.id)}>
          <DeleteIcon />
        </IconButton>
      );
    },
  },
  {
    field: "action2",
    headerName: "رؤية المزيد",
    renderCell: (params: any) => {
      return (
        <IconButton
          color="primary"
          onClick={() => handleRedirect(params.row.id)}
        >
          <RemoveRedEyeIcon />
        </IconButton>
      );
    },
  },
];
