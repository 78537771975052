import { ModifyData, fetchData } from "../../utility/api"

export const fetchClients = async({accessToken}: {accessToken: string}) => {
    const res = await fetchData('/clients', accessToken);
    return res;
}

export const fetchSingleClient = async({id, accessToken}: {id: string; accessToken: string}) => {
    const res = await fetchData(`/clients/${id}`, accessToken);
    return res;
}

export const deleteClients = async({id, accessToken}: {id: string; accessToken: string}) => {
    const res = await ModifyData({
        url: `/clients/${id}`,
        token: accessToken,
        data: {},
        method: "DELETE",
    });
    return res;
}