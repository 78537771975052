import { fetchData } from "../../utility/api";

export interface FetchAttendanceProps {
  page: number;
  attendanceFromDate?: number;
  attendanceToDate?: number;
  accessToken: string;
  limit: number;
  userId?: string;
}

export const fetchAbsence = async ({
  page,
  attendanceFromDate,
  attendanceToDate,
  accessToken,
  limit,
  userId,
}: FetchAttendanceProps) => {
  const res = await fetchData(
    `/attendances?page=${page}${
      attendanceFromDate ? `&attendanceFromDate=${attendanceFromDate}` : ""
    }${
      attendanceToDate ? `&attendanceToDate=${attendanceToDate}` : ""
    }&limit=${limit}${userId?.length ? `&user=${userId}` : ""}`,
    accessToken
  );
  return res;
};
