import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { FetchAttendanceProps, fetchAbsence } from "./absenceApi";

type KnownError = {
  error: { errors: { message: string }[] };
};

export const fetchAbsenseAsync = createAsyncThunk(
    "absense/fetchAbsense", 
    async (
        props: FetchAttendanceProps,
        { rejectWithValue }
      ) => {
        try {
          const res = await fetchAbsence({ ...props });
          return res;
        } catch (err) {
          const error: AxiosError<KnownError> = err as any;
          if (!error.response) {
            throw err;
          }
          if (error.response?.data) {
            const message = error.response?.data?.error?.errors;
            return rejectWithValue(message);
          }
        }
      }
);
