import React from "react";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useNavigate } from "react-router-dom";

import { headers } from "../../pages/AbsenseScreen/AbsenseHeaders";
import { StyledWrapper } from "./StyledDialupComponent";
import { downloadCSV } from "../../utility/helpers";


export const SpeedDialTooltip = ({data}:  { data: any}): React.ReactElement => {
  const navigate = useNavigate();

  const actions = [
    {
      icon: <FileDownloadIcon />,
      name: "download",
      onclick: () => {
        downloadCSV(headers, data)
      },
    },
    { icon: <SearchIcon />, name: "Search", onclick: () => {
      navigate('/absence-search')
    } },
  ];

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <StyledWrapper>
      {" "}
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: "absolute", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={action.onclick}
          />
        ))}
      </SpeedDial>
    </StyledWrapper>
  );
};
