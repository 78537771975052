import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { fetchSoulNote } from "./soulnoteApi";

type KnownError = {
  error: { errors: { message: string }[] };
};

export const fetchSoulnoteAsync = createAsyncThunk(
  "fetchSoulNote",
  async (
    props: { noteDate?: number; accessToken: string },
    { rejectWithValue }
  ) => {
    try {
      const res = await fetchSoulNote({ ...props });
      return res;
    } catch (err) {
      const error: AxiosError<KnownError> = err as any;
      if (!error.response) {
        throw err;
      }
      if (error.response?.data) {
        const message = error.response?.data?.error?.errors;
        return rejectWithValue(message);
      }
    }
  }
);
