import { FormControl, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { sideDrawerWidth } from "../../utility/constants";

export const StyledContentWrapper = styled("div")(({ theme }) => ({
  marginRight: `${sideDrawerWidth}px`,
  [theme.breakpoints.down("sm")]: {
    marginRight: 0,
  },
}));

export const StyledPageTitle = styled(Typography)(({ theme }) => ({
  margin: "2rem 0",
  fontWeight: "bold",
  [theme.breakpoints.down('md')]: {
    fontSize: '1.2rem',
  }
}));

export const StyledFilterationContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  direction: 'rtl'
}));

export const StyledFormController = styled(FormControl)(({ theme }) => ({
    "& .MuiFormLabel-root": {
        color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
        color: theme.palette.primary.main,
        display: 'relative',
        zIndex: 1000
    },
    "& .MuiOutlinedInput-root":{
        color: theme.palette.primary.main
    },

  "& .MuiInputBase-root": {
    "& fieldset": {
      border: 'none',
      color: theme.palette.primary.main,
      boxShadow: '1px 1px 5px gray'
    },
  },
  [theme.breakpoints.down("md")]: {
    "& .MuiInputBase-root": {
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.common.purple,
      },
    },
  },
}));
