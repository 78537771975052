import React, { useState, useEffect } from "react";
import { Toolbar } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";

import { StyedPageWrapper } from "../../components/shareStyles";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchAbsenseAsync } from "../../features/attendence/asyncAbsenceActions";
import { selectUserAuth } from "../../features/auth/authSlice";
import {
  handleCloseAbsenceSnackbar,
  handleSetAbsencePages,
  selectAbsenceErrorMessage,
  selectAbsenceLimit,
  selectAbsencePage,
  selectAbsencePageCount,
  selectAbsences,
  selectAbsencesStatus,
  selectAbsenceTotalCount,
} from "../../features/attendence/absenceSlice";
import { TableComponent } from "../../components/dataGridComponent/dataGridComponent";
import { Columns } from "./AbsensePageColumns";
import { LoaderComponent } from "../../components/loaderComponent/loaderComponent";
import { CustomSnackbarComponent } from "../../components/customSnackbar/customSnackbarComponent";
import {
  StyledTitelsWrapper,
  StyledDateWrapper,
  StyledTypography,
  StyledTextField,
} from "./AbsenceStyles";
import {
  StyledFilterationContainer,
  StyledPageTitle,
} from "../HomePage/HomePageStyles";
import { SpeedDialTooltip } from "../../components/DialupComponent/DialupComponent";
import { StyledFilterationInputsWrapper } from './AbsenceStyles';

export const AbsenseScreen = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  // const [pages, setPages] = useState(1);
  // const limit = 10;
  const [attendanceToDate, setAttendanceToDate] = useState(new Date().valueOf());
  const [attendanceFromDate, setAttendanceFromDate] = useState(new Date().valueOf());
  const [exportToExcelData, setExportToExcelData] = useState<any>([]);
  const userAuth = useAppSelector(selectUserAuth);
  const pages = useAppSelector(selectAbsencePage);
  const limit = useAppSelector(selectAbsenceLimit);
  const totalCount = useAppSelector(selectAbsenceTotalCount);
  const pageCount = useAppSelector(selectAbsencePageCount);
  const absences = useAppSelector(selectAbsences);

  const absenceStatus = useAppSelector(selectAbsencesStatus);
  const absenceErrorMessage = useAppSelector(selectAbsenceErrorMessage);

  useEffect(() => {
    dispatch(
      fetchAbsenseAsync({
        page: pages,
        attendanceToDate: new Date(attendanceToDate).valueOf(),
        attendanceFromDate: new Date(attendanceFromDate).valueOf(),
        accessToken: userAuth?.accessToken,
        limit: limit,
      })
    );
  }, [
    dispatch,
    limit,
    pages,
    attendanceToDate,
    attendanceFromDate,
    userAuth?.accessToken,
  ]);

  useEffect(() => {
    if(exportToExcelData?.length !== absences?.length && absences?.length > 0) {
      const mod = absences.map(a => ({ ...a, ...a.user, absence: 'حضر' }));
      setExportToExcelData(mod);
    }
  }, [absences, exportToExcelData?.length])

  return (
    <StyedPageWrapper style={{ direction: 'rtl' }}>
      <Toolbar />
      <LoaderComponent open={absenceStatus === "loading"} />
      {/* <ExportToExcelButton headers={headers} data={exportToExcelData} /> */}
      <CustomSnackbarComponent
        open={absenceErrorMessage.length > 0}
        handleClose={() => dispatch(handleCloseAbsenceSnackbar())}
        message={absenceErrorMessage}
      />
      <StyledFilterationContainer
        sx={{ display: "flex", alignItems: "center", margin: "1rem" }}
      >
        <StyledTitelsWrapper sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
             display: 'grid',
          alignItems: 'flex-end'
          }
         
        })} >
          <StyledPageTitle variant="h5" sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              margin: 0,
              fontSize: '1.2rem'
            }
          })}> الغياب </StyledPageTitle>
          <StyledPageTitle
            variant="h6"
            sx={(theme) => ({
              color: theme.palette.common.purple,
              padding: "0 1rem",
              [theme.breakpoints.down('md')]: {
                padding: 0,
                margin: 0,
                fontSize: '0.8rem'
              }
            })}
          >
            {" "}
            ( {moment(attendanceFromDate).format("YYYY / MM / DD")} )
          </StyledPageTitle>
        </StyledTitelsWrapper>
        <StyledFilterationInputsWrapper>
          <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label="التاريخ من"
            value={attendanceToDate}
            // minDate={moment(client?.DOB)}
            onChange={(newValue) => {
              setAttendanceToDate(newValue as number);
            }}
            renderInput={(params) => {
              return (
                <StyledDateWrapper sx={(theme) => ({
                  width: '100%',
                  [theme.breakpoints.down('md')]: {
                    width: '17rem'
                  }
                })}>
                  <StyledTypography variant="h6" sx={(theme) => ({
                    [theme.breakpoints.down('md')]: {
                      fontSize: '1rem'
                    }
                  })}>
                    التاريخ من
                  </StyledTypography>
                  <StyledTextField {...params} fullWidth disabled />
                </StyledDateWrapper>
              );
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label="التاريخ الي"
            value={attendanceFromDate}
            // minDate={moment(client?.DOB)}
            onChange={(newValue) => {
              setAttendanceFromDate(newValue as number);
            }}
            renderInput={(params) => {
              return (
                <StyledDateWrapper sx={(theme) => ({
                  width: '100%',
                  [theme.breakpoints.down('md')]: {
                    width: '17rem'
                  }
                })}>
                  <StyledTypography variant="h6" sx={(theme) => ({
                    [theme.breakpoints.down('md')]: {
                      fontSize: '1rem'
                    }
                  })}>
                    التاريخ الي
                  </StyledTypography>
                  <StyledTextField {...params} fullWidth disabled />
                </StyledDateWrapper>
              );
            }}
          />
        </LocalizationProvider>
        </StyledFilterationInputsWrapper>
        
      </StyledFilterationContainer>
      <TableComponent
        columns={Columns()}
        rows={absences}
        rowCount={totalCount}
        onPageChange={(pageNumber) => {
          dispatch(handleSetAbsencePages(pageNumber));
        }}
        limit={limit}
        page={pages}
        pageCount={pageCount}
        isWithPagination={true}
      />
      <SpeedDialTooltip data={exportToExcelData ?? []} />
    </StyedPageWrapper>
  );
};
