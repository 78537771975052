import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import {
  approveClient,
  createServant,
  CreateServantProps,
  deleteServants,
  fetchSignleServant,
  fetchUsers,
  FetchUsersProps,
} from "./servantsApi";

type KnownError = {
  error: { errors: { message: string }[] };
};

export const fetchUsersAsync = createAsyncThunk(
  "servants/fetchUsers",
  async ({ ...data }: FetchUsersProps, { rejectWithValue }) => {
    try {
      const res = await fetchUsers(data);
      return res;
    } catch (err) {
      const error: AxiosError<KnownError> = err as any;
      if (!error.response) {
        throw err;
      }
      if (error.response?.data) {
        const message = error.response?.data?.error?.errors;
        return rejectWithValue(message);
      }
    }
  }
);

export const createServantsAsync = createAsyncThunk(
  "servants/createServants",
  async (
    {
      accessToken,
      props,
    }: {
      accessToken: string;
      props: CreateServantProps;
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await createServant({ accessToken, props });
      return res;
    } catch (err) {
      const error: AxiosError<KnownError> = err as any;
      if (!error.response) {
        throw err;
      }
      if (error.response?.data) {
        const message = error.response?.data?.error?.errors;
        return rejectWithValue(message);
      }
    }
  }
);

export const deleteServantsAsync = createAsyncThunk(
  "servants/delete",
  async (
    { id, accessToken }: { id: string; accessToken: string },
    { rejectWithValue }
  ) => {
    try {
      const res = await deleteServants({ id, accessToken });
      return res;
    } catch (err) {
      const error: AxiosError<KnownError> = err as any;
      if (!error.response) {
        throw err;
      }
      if (error.response?.data) {
        const message = error.response?.data?.error?.errors;
        return rejectWithValue(message);
      }
    }
  }
);

export const fetchSignleServantAsync = createAsyncThunk(
  "servants/fetchSignleServant",
  async (
    { id, accessToken }: { id: string; accessToken: string },
    { rejectWithValue }
  ) => {
    try {
      const res = await fetchSignleServant({ id, accessToken });
      return res;
    } catch (err) {
      const error: AxiosError<KnownError> = err as any;
      if (!error.response) {
        throw err;
      }
      if (error.response?.data) {
        const message = error.response?.data?.error?.errors;
        return rejectWithValue(message);
      }
    }
  }
);

export const approveClientAsync = createAsyncThunk(
  "servants/approveClients",
  async (
    { ids, accessToken }: { ids: string[]; accessToken: string },
    { rejectWithValue }
  ) => {
    try {
      const res = await approveClient({ ids, accessToken });
      return res;
    } catch (err) {
      const error: AxiosError<KnownError> = err as any;
      if (!error.response) {
        throw err;
      }
      if (error.response?.data) {
        const message = error.response?.data?.error?.errors;
        return rejectWithValue(message);
      }
    }
  }
);
