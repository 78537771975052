import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  ".MuiDataGrid-columnHeaders": {
    backgroundColor: theme.palette.common.darkSelver,
    color: theme.palette.common.white,
    fontSize: "1rem",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  ".MuiDataGrid-columnHeadersInner": {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  ".MuiDataGrid-columnHeaderRow": {
    width: '100%',
    display: 'grid',
    justifyContent: 'space-between'
  },
//   ".MuiDataGrid-column": {
//     width: '200px !important',
//     maxWidth: '300px !important'
    
//   },
  ".MuiDataGrid-columnSeparator": {
    display: "none",
  },
  ".MuiDataGrid-cellContent": {
    fontSize: "1rem",
  },
  ".MuiDataGrid-menuIcon": {
    display: "none",
  },

}));
