import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { deleteClients, fetchClients, fetchSingleClient } from "./clientsApi";

type KnownError = {
  error: { errors: { message: string }[] };
};

export const fetchClientsAsync = createAsyncThunk(
  "clients/fetchClients",
  async ({ accessToken }: { accessToken: string }, { rejectWithValue }) => {
    try {
      const res = await fetchClients({ accessToken });
      return res;
    } catch (err) {
      const error: AxiosError<KnownError> = err as any;
      if (!error.response) {
        throw err;
      }
      if (error.response?.data) {
        const message = error.response?.data?.error?.errors;
        return rejectWithValue(message);
      }
    }
  }
);

export const fetchSingleClientAsync = createAsyncThunk(
  "clients/fetchSingleClient",
  async (props: { id: string; accessToken: string }, { rejectWithValue }) => {
    try {
      const res = await fetchSingleClient({ ...props });
      return res;
    } catch (err) {
      const error: AxiosError<KnownError> = err as any;
      if (!error.response) {
        throw err;
      }
      if (error.response?.data) {
        const message = error.response?.data?.error?.errors;
        return rejectWithValue(message);
      }
    }
  }
);

export const deleteClientsAsync = createAsyncThunk(
  'clients/deleteClient',
  async(props: { id: string; accessToken: string }, { rejectWithValue }) => {
    try {
      const res = await deleteClients(props);
      return res;
    } catch (err) {
      const error: AxiosError<KnownError> = err as any;
      if (!error.response) {
        throw err;
      }
      if (error.response?.data) {
        const message = error.response?.data?.error?.errors;
        return rejectWithValue(message);
      }
    }
  }
)
