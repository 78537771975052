import React from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { StyledCSVLink } from "./ExportToExcelStyles";

export interface ExportToExcelProps {
  data: any;
  headers: any;
  isFloatingAction?: boolean;
  fileName: string;
}

export const ExportToExcelButton = ({
  data,
  headers,
  isFloatingAction = false,
  fileName
}: ExportToExcelProps): React.ReactElement => {
  // media={meta}
  return (
    <StyledCSVLink
      sx={(theme) =>
        isFloatingAction
          ? {
              position: "absolute",
              bottom: theme.spacing(5),
              boxShadow: theme.shadows[12],
            }
          : {}
      }
      data={data}
      headers={headers}
      filename={fileName}
      target="_blank"
    >
      <FileDownloadIcon />
    </StyledCSVLink>
  );
};
