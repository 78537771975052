import { Button, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledInputsWrapper = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '44% 44%',
    gridGap: '1rem',
    margin: '0.5rem 1rem',
    [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '100%',
    }
}));

export const StyledDateWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    direction: 'ltr'
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.purple,
    marginBottom: '8px'
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
}));

export const StyledButtonWrapper = styled('div')(({ theme }) => ({
    marginTop: '2rem',
    width: '100%',
    display: 'grid',
    justifyContent: 'center'
}));

export const StyledButton = styled(Button)(({theme}) => ({
    padding: '1rem 10rem',
    fontWeight: 'bold',
    fontSize: '1rem',
    [theme.breakpoints.down('md')]: {
        padding: '1rem 7rem',
    }
}));