import React, { useState, useEffect } from "react";
import { Toolbar, Typography } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useNavigate } from "react-router-dom";

import { StyedPageWrapper } from "../../components/shareStyles";
import {
  StyledInputsWrapper,
  StyledDateWrapper,
  StyledTypography,
  StyledTextField,
  StyledButtonWrapper,
  StyledButton,
} from "./CreateServantStyles";
import CustomInput from "../../components/inputComponent/inputComponent";
import { verifyAllForm } from "../../utility/helpers";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { createServantsAsync } from "../../features/servants/asyncServantsActions";
import { selectUserAuth } from "../../features/auth/authSlice";
import { CreateServantProps } from "../../features/servants/servantsApi";
import { LoaderComponent } from "../../components/loaderComponent/loaderComponent";
import {
  handleCloseServantSnackbar,
  selectServantsErrorMessage,
  selectServantsStatus,
} from "../../features/servants/servantsSlice";
import { CustomSnackbarComponent } from "../../components/customSnackbar/customSnackbarComponent";

export interface Values {
  name: string;
  DOB: string;
  phone: string;
  educationalLevelOrWork: string;
  whatsapp: string;
  fatherOfConfession: string;
  email: string;
  church: string;
  address: string;
  service: string;
  password: string;
}

export const CreateServantPage = (): React.ReactElement => {
  const [values, setValues] = useState<Values>({
    name: "",
    DOB: "",
    phone: "",
    educationalLevelOrWork: "",
    whatsapp: "",
    fatherOfConfession: "",
    email: "",
    church: "",
    address: "",
    service: "",
    password: "",
  });
  const [showErrors, setShowErrors] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userAuth = useAppSelector(selectUserAuth);
  const servantsStatus = useAppSelector(selectServantsStatus);
  const servantsErrorMessage = useAppSelector(selectServantsErrorMessage);

  useEffect(() => {
    if (servantsStatus === "success") {
      navigate("/servants");
    }
  }, [navigate, servantsStatus]);

  const handleInputChange = (label: keyof Values, e: string) => {
    setValues((prevState) => ({ ...prevState, [label]: e }));
  };

  const handleSubmit = () => {
    if (
      verifyAllForm([
        {
          value: values.DOB,
          verification: "required",
        },
        {
          value: values.address,
          verification: "required",
        },
        {
          value: values.church,
          verification: "required",
        },
        {
          value: values.educationalLevelOrWork,
          verification: "required",
        },
        {
          value: values.email,
          verification: "email",
        },
        {
          value: values.fatherOfConfession,
          verification: "required",
        },
        {
          value: values.name,
          verification: "required",
        },
        {
          value: values.password,
          verification: "required",
        },
        {
          value: values.phone,
          verification: "required",
        },
        {
          value: values.whatsapp,
          verification: "required",
        },
      ])
    ) {
      let data: CreateServantProps = {
        ...values,
        DOB: new Date(values.DOB).valueOf() as number,
      };
      if (data.service && data?.service.length === 0) {
        // @ts-ignore
        delete data.service;
      }
      dispatch(
        createServantsAsync({ accessToken: userAuth.accessToken, props: data })
      );
    } else {
      setShowErrors(true);
    }
  };

  return (
    <StyedPageWrapper style={{ direction: 'rtl' }}>
      <Toolbar />
      <LoaderComponent open={servantsStatus === "loading"} />
      <CustomSnackbarComponent
        open={servantsErrorMessage.length > 0}
        handleClose={() => {
          dispatch(handleCloseServantSnackbar());
        }}
        message={servantsErrorMessage}
      />
      <Typography variant="h5" fontWeight="bold" sx={{ margin: "2rem 1rem" }}>
        اضافة خادم
      </Typography>
      <StyledInputsWrapper>
        <CustomInput
          labelName="الاسم رباعى"
          inputType="text"
          handleInputChange={(e) => {
            handleInputChange("name", e);
          }}
          placeholder="ادخل الاسم رباعى"
          value={values.name}
          uniqueIdentifier="user-name"
          showErrors={showErrors}
          verification="required"
          StartAdornment="name"
        />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label="تاريخ الميلاد"
            value={values.DOB}
            // minDate={moment(values.DOB)}
            onChange={(newValue) => {
              handleInputChange("DOB", newValue?.toString() as string);
            }}
            renderInput={(params) => {
              return (
                <StyledDateWrapper>
                  <StyledTypography variant="h6" textAlign="end">
                    تاريخ الميلاد
                  </StyledTypography>
                  <StyledTextField {...params} fullWidth />
                </StyledDateWrapper>
              );
            }}
          />
        </LocalizationProvider>
        <CustomInput
          labelName="البريد الالكترونى"
          inputType="email"
          handleInputChange={(e) => {
            handleInputChange("email", e);
          }}
          placeholder="ادخل البريد الالكترونى"
          value={values.email}
          uniqueIdentifier="email-input"
          showErrors={showErrors}
          verification="email"
          StartAdornment="email"
        />
        <CustomInput
          labelName="رقم الموبايل"
          inputType="number"
          handleInputChange={(e) => handleInputChange("phone", e)}
          value={values.phone ? Number(values.phone) : ""}
          uniqueIdentifier="phone-number"
          placeholder="ادخل رقم الموبايل"
          showErrors={showErrors}
          verification="required"
          StartAdornment="phone"
        />
        <CustomInput
          labelName="الكنيسه (المنتظم فيها)"
          inputType="text"
          handleInputChange={(e) => handleInputChange("church", e)}
          value={values.church}
          uniqueIdentifier="church"
          placeholder="ادخل اسم الكنيسة"
          showErrors={showErrors}
          verification="required"
          StartAdornment="church"
        />
        <CustomInput
          labelName="رقم ال Whatsapp"
          inputType="number"
          handleInputChange={(e) => handleInputChange("whatsapp", e)}
          value={values.whatsapp ? Number(values.whatsapp) : ""}
          uniqueIdentifier="whatsapp-number"
          placeholder="ادخل رقم WhatsApp"
          showErrors={showErrors}
          verification="required"
          StartAdornment="phone"
        />
        <CustomInput
          labelName="أب الاعتراف"
          inputType="text"
          handleInputChange={(e) => handleInputChange("fatherOfConfession", e)}
          value={values.fatherOfConfession}
          uniqueIdentifier="father-Of-confession"
          placeholder="ادخل اسم الكاهن"
          showErrors={showErrors}
          verification="required"
          StartAdornment="priest"
        />
        <CustomInput
          labelName="المرحلة الدراسية أو العمل"
          inputType="text"
          handleInputChange={(e) =>
            handleInputChange("educationalLevelOrWork", e)
          }
          value={values.educationalLevelOrWork ?? ""}
          uniqueIdentifier="educ-lovel-or-work"
          placeholder="ادخل المرحله الدراسيه او العمل"
          showErrors={showErrors}
          verification="required"
          StartAdornment="gradHat"
        />
        <CustomInput
          labelName="الخدمة الحالية (ان وجدت)"
          inputType="text"
          handleInputChange={(e) => handleInputChange("service", e)}
          value={values.service}
          uniqueIdentifier="service"
          placeholder="ادخل اسم الخدمة"
          showErrors={showErrors}
          verification="required"
        />
        <CustomInput
          labelName="العنوان"
          inputType="text"
          handleInputChange={(e) => handleInputChange("address", e)}
          value={values.address}
          placeholder="غير محدد"
          uniqueIdentifier="address"
          showErrors={showErrors}
          verification="required"
          StartAdornment="address"
        />
        <CustomInput
          inputType="password"
          StartAdornment="password"
          handleInputChange={(e) => handleInputChange("password", e)}
          value={values.password}
          uniqueIdentifier="password"
          showPassword={showPassword}
          labelName="كلمة المرور"
          handleShowPass={() => setShowPassword(true)}
          showErrors={showErrors}
          verification="required"
          placeholder="ادخل كلمة السر"
        />
      </StyledInputsWrapper>
      <StyledButtonWrapper>
        <StyledButton variant="contained" onClick={handleSubmit}>
          حفظ التغيرات
        </StyledButton>
      </StyledButtonWrapper>
    </StyedPageWrapper>
  );
};
