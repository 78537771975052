import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { login } from "./authApi";

type KnownError = {
  error: { errors: { message: string }[] };
};

export const loginAsync = createAsyncThunk(
  "auth/login",
  async (
    { email, password }: { email: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const res = await login({ email, password });
      return res;
    } catch (err) {
      const error: AxiosError<KnownError> = err as any;
      if (!error.response) {
        throw err;
      }
      if (error.response?.data) {
        const message = error.response?.data?.error?.errors;
        return rejectWithValue(message);
      }
    }
  }
);
