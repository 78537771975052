import { fetchData, ModifyData } from "../../utility/api";

export interface CreateServantProps {
  name: string;
  email: string;
  phone: string;
  password: string;
  DOB: number;
  educationalLevelOrWork: string;
  fatherOfConfession: string;
  church: string;
  whatsapp: string;
  address: string;
  service: string;
}

export interface FetchUsersProps {
  page?: number;
  limit?: number;
  email?: string;
  name?: string;
  verified?: boolean;
  allowed?: boolean;
  roles?: "USER" | "CLIENT" | "ADMIN" | "SUPER_ADMIN" | "SERVANT";
  accessToken: string;
}

export const fetchUsers = async ({
  page = 1,
  limit = 10,
  email,
  name,
  verified,
  allowed,
  roles = "SERVANT",
  accessToken,
}: FetchUsersProps) => {
  const url: string = `/users?page=${page}&limit=${limit}${
    email?.length ? `&email=${email}` : ""
  }${name?.length ? `&name=${name}` : ""}${
    typeof verified === "boolean" ? `&verified=${verified}` : ""
  }${typeof allowed === "boolean" ? `&allowed=${allowed}` : ""}&roles=${roles}`;

  const res = await fetchData(url, accessToken);
  return res;
};

export const createServant = async ({
  accessToken,
  props,
}: {
  props: CreateServantProps;
  accessToken: string;
}) => {
  const res = await ModifyData({
    url: "/servants",
    data: { ...props },
    token: accessToken,
  });
  return res;
};

export const deleteServants = async ({
  id,
  accessToken
}: {id: string; accessToken: string}) => {
  const res = await ModifyData({
    url: `servants/${id}`,
    token: accessToken,
    data: {},
    method: 'DELETE'
  })
  return res;
}

export const fetchSignleServant = async({
  id, 
  accessToken
}: {id: string; accessToken: string}) => {
  const res = await fetchData(`/servants/${id}`, accessToken);
  return res;
}

export const approveClient = async({
  ids,
  accessToken
}: {ids: string[]; accessToken: string}) => {
  const res = await ModifyData({
    url: '/clients/allow',
    data: {
      ids
    },
    token: accessToken,
    method: 'PUT'
  })
  return res;
}
