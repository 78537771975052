import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import ScaleIcon from '@mui/icons-material/Scale';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import LockIcon from '@mui/icons-material/Lock';
import ChurchIcon from '@mui/icons-material/Church';
import BoyIcon from '@mui/icons-material/Boy';
import SchoolIcon from '@mui/icons-material/School';

import { Adornments } from '../types/Adornments';

export const StyledAdornment = {padding: '8px', color: '#652943'}

export const GetAdornment = (a: Adornments) => {
  switch (a) {
    case 'name':
      return <PersonIcon sx={{ fontSize: '2.5rem'}} style={StyledAdornment} />;

    case 'email':
      return <EmailIcon sx={{ fontSize: '2.5rem'}} style={StyledAdornment} />;

    case 'phone':
      return <PhoneIphoneIcon sx={{ fontSize: '2.5rem'}} style={StyledAdornment} />;

    case 'bike':
      return <DirectionsBikeIcon sx={{ fontSize: '2.5rem'}} style={StyledAdornment} />;

    case 'color':
      return <ColorLensIcon sx={{ fontSize: '2.5rem'}} style={StyledAdornment} />;

    case 'weight':
      return <ScaleIcon sx={{ fontSize: '2.5rem'}} style={StyledAdornment} />;

    case 'address':
      return <EditLocationAltIcon sx={{ fontSize: '2.5rem'}} style={StyledAdornment} />;

    case 'password':
      return <LockIcon sx={{ fontSize: '2.5rem'}} style={StyledAdornment} />;

    case 'church': 
      return  <ChurchIcon sx={{ fontSize: '2.5rem'}} style={StyledAdornment} />;

    case 'priest':
      return <BoyIcon sx={{ fontSize: '2.5rem'}} style={StyledAdornment} />;

    case 'gradHat': 
      return <SchoolIcon sx={{ fontSize: '2.5rem'}} style={StyledAdornment} />
      
    default:
      return <PersonIcon sx={{ fontSize: '2.5rem'}} style={StyledAdornment} />;
  }
};
