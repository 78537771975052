import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

export const LoginWrapper = styled("div")(({ theme }) => ({
  display: "grid",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "2rem",
  direction: 'rtl',
  [theme.breakpoints.down('md')]: {
    marginTop: 0,
    height: '100vh',
  }
}));

export const StyledVideoBackground = styled("video")(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  width: "100vw",
  zIndex: -100,
  objectFit: "cover",
  [theme.breakpoints.down("lg")]: {
    height: "100vh",
    width: "100vw",
  },
}));

export const StyledVideoOverlay = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: `100vh`,
  zIndex: -90,
  backgroundColor: "rgba(0,0,0,0.5)",
}));

export const StyledLoginContent = styled(Paper)(({ theme }) => ({
  padding: "3rem 5rem",
  width: "25vw",
  borderRadius: "0.4rem",
  backgroundColor: theme.palette.common.secondary,
  zIndex: 1000,
  [theme.breakpoints.down('md')]: {
    width: '80vw',
    padding: '2rem'
  }
}));

export const StyledForm = styled("form")(({ theme }) => ({}));

export const ImageWrapper = styled("div")(({ theme }) => ({
  display: "grid",
  justifyContent: "center",
}));

export const StyledImage = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '10rem',
    height: '10rem'
  }
}))

export const InputsWrapper = styled("div")(({ theme }) => ({
  marginTop: "4rem",
  display: "grid",
  gridGap: "1.2rem",
}));
