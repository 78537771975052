import { FormVerification } from "../types/FormVerification";
import FileSaver from 'file-saver';
import { CsvHeaders } from "./headers";

interface HandleVerifyArgd<T> {
  verification: FormVerification;
  value: T;
  secondValue?: T;
}

export const hashString = (str: string) => {
  return str.replace(/^.{1,5}/, (m) => "*".repeat(m.length));
};

export const handleVerify = <T>({
  verification,
  value,
  secondValue,
}: HandleVerifyArgd<T>): string => {
  let errorMessage = "";
  switch (verification) {
    case "email":
      errorMessage =
        typeof value === "string" &&
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)
          ? ""
          : "Please enter a valid email";
      break;

    case "required":
      const isValueValid =
        typeof value === "number"
          ? value > 0
          : (typeof value === "string" || Array.isArray(value)) &&
            value.length > 0;

      errorMessage = isValueValid ? "" : "This field is required";
      break;
    case "repeatEmail":
      errorMessage =
        secondValue && typeof value === "string" && secondValue === value
          ? ""
          : "Emails does not match";
      break;
    case "repeatPassword":
      errorMessage =
        secondValue && typeof value === "string" && secondValue === value
          ? ""
          : "Passwords does not match";
      break;

    default:
      break;
  }
  return errorMessage;
};

export const verifyAllForm = (
  values: {
    value: string | string[];
    verification: FormVerification;
    secondValue?: string | string[];
  }[]
): boolean => {
  let isValid = true;
  for (let i = 0; i < values.length; i++) {
    if (handleVerify(values[i])) {
      isValid = false;
      break;
    }
  }
  return isValid;
};

export function toCSV(headers: CsvHeaders[], data: any) {
  const rows = [];
  const separator = ',';
  // Get the column headers from the "label" properties of the "headers" objects
  const columnHeaders = headers.map((header) => header.label);
  rows.push(columnHeaders.join(separator));
  // Add the data rows
  data.forEach((row: any) => {
    const values = headers.map((header) => row[header.key]);
    rows.push(values.join(separator));
  });
  // Join the rows with line breaks and return the CSV string
  return rows.join('\n');
}

export function downloadCSV(headers: CsvHeaders[], data: any) {
  // const labelHeaders = headers.map((header) => header.label);
  // const keyHeaders = headers.map((header) => header.key);
  const csvData = toCSV(headers, data); // convert headers and data to CSV string
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' }); // create blob from CSV string
  FileSaver.saveAs(blob, 'data.csv'); // download file with name "data.csv"
}
