import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { CustomSnackbarComponent } from "./components/customSnackbar/customSnackbarComponent";
import { LoaderComponent } from "./components/loaderComponent/loaderComponent";
import {
  handleCloseAuthSnackbar,
  selectUserAuth,
  selectUserAuthErrorMessage,
  selectUserAuthStatus,
} from "./features/auth/authSlice";
import { HomePageScreen } from "./pages/HomePage/HomePage";

import { SideDrawer } from './components/SideDrawerComponent/SideDrawerComponent';
import { LoginPage } from "./pages/login/LoginPage";
import { handleCloseClientSnackbar, selectClientsStatus } from "./features/clients/clientsSlice";
import { ClientPage } from "./pages/ClientPage/ClientPage";
import { SoulNotePage } from "./pages/SoulNote/SoulNote";
import { AbsenseScreen } from "./pages/AbsenseScreen/AbsenseScreen";
import { ServantsPage } from "./pages/ServantsPage/ServantsPage";
import { CreateServantPage } from "./pages/CreateServant/CreateServant";
import { NotAllowedUsers } from "./pages/NotAllowedUsers/NotAllowedUsers";
import { AbsensePerPerson } from "./pages/AbsensePerPerson/AbsensePerPerson";

const App = (): React.ReactElement => {
  const userData = useAppSelector(selectUserAuth);
  const userAuthStatus = useAppSelector(selectUserAuthStatus);
  const userAuthErrorMessage = useAppSelector(selectUserAuthErrorMessage);
  const clientStatus = useAppSelector(selectClientsStatus);
  const clientsErrorMessage = useAppSelector(selectUserAuthErrorMessage);
  const dispatch = useAppDispatch();

  return (
    <Router>
      {
        userData?.accessToken && <SideDrawer /> 
      }
      {/* <SideDrawer /> */}
      <LoaderComponent open={userAuthStatus === "loading" || clientStatus === 'loading'} />
      <CustomSnackbarComponent
        open={userAuthErrorMessage.length > 0 || clientsErrorMessage.length > 0}
        handleClose={() => {
          dispatch(handleCloseAuthSnackbar())
          dispatch(handleCloseClientSnackbar())
        }}
        message={userAuthErrorMessage || clientsErrorMessage}
      />
      <Routes>
        {userData?.accessToken ? (
          <React.Fragment>
            <Route path="/" element={<HomePageScreen />} />
            <Route path='/client/:id' element={<ClientPage />} />
            <Route path='/soulnote' element={<SoulNotePage />} />
            <Route path='/absence' element={<AbsenseScreen />} />
            <Route path='/absence-search' element={<AbsensePerPerson />} />
            <Route path='/servants' element={<ServantsPage />} />
            <Route path='/create-servant' element={<CreateServantPage />} />
            <Route path='/not-allowed-users' element={<NotAllowedUsers />} />
          </React.Fragment>
        ) : (
          <Route path="/" element={<LoginPage />} />
        )}
      </Routes>
    </Router>    
  );
};

export default App;
