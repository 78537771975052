export const headers = [
  {
    label: "التاريخ",
    key: "attendanceDate"
  },
  {
    label: "الاسم",
    key: 'name'
  }, {
    label: "الحضور",
    key: 'absence'
  },
]