import React, { useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import {
  ToolbarWrapper,
  UserNameTypography,
  StyledAppBar,
  TitleWrapper,
  StyledDrawerWrapper,
  StyledLogoutBotton,
} from "./SideDrawerStyles";
import { sideDrawerWidth } from "../../utility/constants";
import { DrawerComponent } from "./mobileDrawer";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectUserAuth, signout } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";

export const SideDrawer = (): React.ReactElement => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const userAuth = useAppSelector(selectUserAuth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const container =
    window !== undefined ? () => window.document.body : undefined;
  return (
    <Box sx={{ display: "flex", direction: 'rtl' }}>
      <CssBaseline />
      <StyledAppBar position="fixed">
        <ToolbarWrapper>
          <TitleWrapper>
            <Typography variant="h6" noWrap color="secondary" component="div">
              مرحباً بك:
            </Typography>
            <UserNameTypography variant="h6" noWrap color="secondary">
              {userAuth?.user?.name ?? ""}
            </UserNameTypography>
          </TitleWrapper>
          <StyledLogoutBotton
            onClick={() => {
              dispatch(signout());
              navigate("/");
            }}
          />
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </ToolbarWrapper>
      </StyledAppBar>
      <StyledDrawerWrapper
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
        }}
        anchor="right"
      >
        <DrawerComponent />
      </StyledDrawerWrapper>
      <StyledDrawerWrapper
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: sideDrawerWidth,
          },
          backgroundColor: "green",
        }}
        anchor="right"
        open
      >
        <DrawerComponent />
      </StyledDrawerWrapper>
    </Box>
  );
};
