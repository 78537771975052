import React, { useEffect } from "react";
import { Toolbar } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { StyedPageWrapper } from "../../components/shareStyles";
import { CustomSnackbarComponent } from "../../components/customSnackbar/customSnackbarComponent";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  handleCloseServantSnackbar,
  handleDeleteServant,
  handleSetServantsPages,
  selectServantPageNumber,
  selectServants,
  selectServantsLimitNumber,
  selectServantsPageCount,
  selectServantsStatus,
  selectServantsSuccessMessage,
} from "../../features/servants/servantsSlice";
import {
  StyledFilterationContainer,
  StyledPageTitle,
} from "../HomePage/HomePageStyles";
import { StyledTitelsWrapper } from "../AbsenseScreen/AbsenceStyles";
import { StyledAddServantButton } from "./ServantPageStyles";
import { Columns } from "./ServantsPageColumns";
import { TableComponent } from "../../components/dataGridComponent/dataGridComponent";
import { useNavigate } from "react-router-dom";
import {
  deleteServantsAsync,
  fetchUsersAsync,
} from "../../features/servants/asyncServantsActions";
import { useSelector } from "react-redux";
import { selectUserAuth } from "../../features/auth/authSlice";
import { LoaderComponent } from "../../components/loaderComponent/loaderComponent";

export const ServantsPage = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userAuth = useSelector(selectUserAuth);
  const servantsSuccessMessage = useAppSelector(selectServantsSuccessMessage);
  const servantsStatus = useAppSelector(selectServantsStatus);
  const page = useAppSelector(selectServantPageNumber);
  const limit = useAppSelector(selectServantsLimitNumber);
  const pageCount = useAppSelector(selectServantsPageCount);
  const servants = useAppSelector(selectServants);

  useEffect(() => {
    dispatch(
      fetchUsersAsync({ accessToken: userAuth?.accessToken, page, limit })
    );
  }, [dispatch, limit, page, userAuth?.accessToken]);

  const handleRedirect = (id: string) => {
    navigate(`/client/${id}`, { state: { isServant: true } });
  };

  const handleDelete = (id: string) => {
    dispatch(deleteServantsAsync({ id, accessToken: userAuth?.accessToken }));
    const syncServants = [...servants];
    const filteredArray = syncServants.filter((s) => {
      return s.id !== id;
    });
    dispatch(handleDeleteServant(filteredArray));
  };
  return (
    <StyedPageWrapper>
      <Toolbar />
      <CustomSnackbarComponent
        open={servantsSuccessMessage.length > 0 && servantsStatus === "success"}
        handleClose={() => dispatch(handleCloseServantSnackbar())}
        message={servantsSuccessMessage}
        severity="success"
      />
      <LoaderComponent open={servantsStatus === "loading"} />
      <StyledFilterationContainer
        sx={{ display: "flex", alignItems: "center", margin: "1rem" }}
      >
        <StyledTitelsWrapper>
          <StyledPageTitle variant="h5"> خادم مسؤول </StyledPageTitle>
        </StyledTitelsWrapper>
        <StyledAddServantButton
          onClick={(e) => {
            dispatch(handleCloseServantSnackbar());
            navigate("/create-servant");
          }}
          variant="contained"
          startIcon={<AddIcon sx={{ color: "white", marginLeft: "1rem" }} />}
        >
          إضافة خادم مسؤول
        </StyledAddServantButton>
      </StyledFilterationContainer>
      <TableComponent
        columns={Columns({ handleRedirect, handleDelete })}
        rows={servants}
        isWithPagination
        limit={limit}
        page={page}
        pageCount={pageCount}
        onPageChange={(pageNumber) => {
          dispatch(handleSetServantsPages(pageNumber));
        }}
      />
    </StyedPageWrapper>
  );
};
