import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';
import authReducer from '../features/auth/authSlice';
import clientReducer from '../features/clients/clientsSlice';
import soulNoteReducer from '../features/soulNote/soulNoteSlice';
import absenceReducer from '../features/attendence/absenceSlice';
import servantReducer from '../features/servants/servantsSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    clients: clientReducer,
    soulnote: soulNoteReducer,
    absence: absenceReducer,
    servants: servantReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
