import { fetchData } from "../../utility/api";

export const fetchSoulNote = async ({
  noteDate,
  accessToken,
}: {
  noteDate?: number;
  accessToken: string;
}) => {
  const res = await fetchData(`notes?${noteDate && `noteDate=${noteDate}`}`, accessToken);
  return res;
};
