import { AppBar, Drawer, List, ListItem, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { sideDrawerWidth } from "../../utility/constants";

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  width: `calc(100% - ${sideDrawerWidth}px)`,
  marginRight: `${sideDrawerWidth}px`,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    margin: 0,
  },
}));

export const ToolbarWrapper = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: theme.palette.common.white,
}));

export const TitleWrapper = styled("div")(({ theme }) => ({
  display: "flex",
}));

export const UserNameTypography = styled(Typography)(({ theme }) => ({
  padding: "0 0.2rem",
  color: theme.palette.primary.main,
}));

export const StyledDrawerWrapper = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    width: sideDrawerWidth,
    backgroundColor: '#FAFAFA'
  },
}));

export const StyledList = styled(List)(({ theme }) => ({
  "& .MuiList-root": {
    "& .MuiListItem-root": {
      "& .Mui-selected": {
        color: 'white'
      },
    },
  },
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&.MuiListItem-root": {
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    marginTop: '1rem'
  },
  
}));

export const StyledLogoutBotton = styled(ExitToAppIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '2.5rem',
  transform: 'scale(-1)', 
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));

export const StyledSidenavWrapper = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  direction: 'rtl'
}));

export const StyledLogoutWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  padding: '1rem'
}))

export const StyledMobileLogoutButton = styled(ExitToAppIcon) (({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '2.5rem',
  transform: 'scale(-1)', 
  [theme.breakpoints.up('sm')]: {
    display: 'none'
  }
}))
