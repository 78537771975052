import { Typography } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import LogoImage from "../../assets/images/loginImage.svg";
import { useLocation, useNavigate } from "react-router-dom";

import {
  StyledListItem,
  StyledList,
  StyledSidenavWrapper,
  StyledMobileLogoutButton,
  StyledLogoutWrapper,
} from "./SideDrawerStyles";
import { useAppDispatch } from "../../app/hooks";
import { signout } from "../../features/auth/authSlice";

export const DrawerComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleNavigation = (route: string) => {
    if (route !== location.pathname) {
      navigate(route);
    }
  };
  return (
    <StyledSidenavWrapper>
      <div>
        <div style={{ display: "grid", justifyContent: "center" }}>
          <img
            src={LogoImage}
            alt="logo"
            style={{ width: "11rem", height: "11rem" }}
          />
        </div>
        <StyledList>
          <StyledListItem
            disablePadding
            selected={location.pathname === "/"}
            onClick={() => handleNavigation("/")}
          >
            <ListItemButton>
              <Typography variant="h6">المستخدمين</Typography>
            </ListItemButton>
          </StyledListItem>
          <StyledListItem
            disablePadding
            selected={location.pathname === "/not-allowed-users"}
            onClick={() => handleNavigation("/not-allowed-users")}
          >
            <ListItemButton>
              <Typography variant="h6"> المستخدمين الجدد (في انتظار الموافقة)</Typography>
            </ListItemButton>
          </StyledListItem>
          <StyledListItem
            disablePadding
            selected={location.pathname === "/soulnote"}
            onClick={() => handleNavigation("/soulnote")}
          >
            <ListItemButton>
              <Typography variant="h6">النوتة الروحية</Typography>
            </ListItemButton>
          </StyledListItem>
          <StyledListItem
            disablePadding
            selected={location.pathname === "/absence"}
            onClick={() => handleNavigation("/absence")}
          >
            <ListItemButton>
              <Typography variant="h6">الغياب</Typography>
            </ListItemButton>
          </StyledListItem>
          <StyledListItem
            disablePadding
            selected={location.pathname === "/servants"}
            onClick={() => handleNavigation("/servants")}
          >
            <ListItemButton>
              <Typography variant="h6">خادم مسؤول </Typography>
            </ListItemButton>
          </StyledListItem>
        </StyledList>
      </div>
      <StyledLogoutWrapper
        onClick={() => {
          dispatch(signout());
          navigate('/')
        }}
      >
        <StyledMobileLogoutButton />
        <Typography
          variant="body1"
          color="primary"
          sx={{ marginRight: "0.5rem" }}
        >
          تسجيل الخروج
        </Typography>
      </StyledLogoutWrapper>
    </StyledSidenavWrapper>
  );
};
