import { GridColDef } from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";

export const Columns: GridColDef[] = [
  {
    field: "name",
    headerName: "الاسم",
    sortable: false,
    filterable: false,
    width: 240,
    renderCell: (params: any) => {
      return (
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
            color: (theme) => theme.palette.common.purple,

          }}
        >
          {params.row.user.name}
        </Typography>
      );
    },
  },
  {
    field: "earlyPray",
    headerName: "الصلاة (باكر)",
    sortable: false,
    filterable: false,
    width: 120,
    renderCell: (params: any) => {
      return (
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
            color: (theme) => theme.palette.common.purple,
          }}
        >
          {params.row.earlyPray ? (
            <DoneAllIcon color="success" />
          ) : (
            <CloseIcon color="error" />
          )}
        </Typography>
      );
    },
  },
  {
    field: "sunsetPray",
    headerName: "الصلاة (غروب)",
    sortable: false,
    filterable: false,
    width: 120,
    renderCell: (params: any) => {
      return (
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
            color: (theme) => theme.palette.common.purple,
          }}
        >
          {params.row.sunsetPray ? (
            <DoneAllIcon color="success" />
          ) : (
            <CloseIcon color="error" />
          )}
        </Typography>
      );
    },
  },
  {
    field: "sleepPray",
    headerName: "الصلاة (النوم)",
    sortable: false,
    filterable: false,
    width: 120,
    renderCell: (params: any) => {
      return (
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
            color: (theme) => theme.palette.common.purple,
          }}
        >
          {params.row.sleepPray ? (
            <DoneAllIcon color="success" />
          ) : (
            <CloseIcon color="error" />
          )}
        </Typography>
      );
    },
  },
  {
    field: "bible",
    headerName: "الكتاب المقدس",
    sortable: false,
    filterable: false,
    width: 120,
    renderCell: (params: any) => {
      return (
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
            color: (theme) => theme.palette.common.purple,
          }}
        >
          {params.row.bible ? (
            <DoneAllIcon color="success" />
          ) : (
            <CloseIcon color="error" />
          )}
        </Typography>
      );
    },
  },
  {
    field: "eating",
    headerName: "التناول",
    sortable: false,
    filterable: false,
    width: 120,
    renderCell: (params: any) => {
      return (
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
            color: (theme) => theme.palette.common.purple,
          }}
        >
          {params.row.eating ? (
            <DoneAllIcon color="success" />
          ) : (
            <CloseIcon color="error" />
          )}
        </Typography>
      );
    },
  },
  {
    field: "confession",
    headerName: "الاعتراف",
    sortable: false,
    filterable: false,
    width: 120,
    renderCell: (params: any) => {
      return (
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
            color: (theme) => theme.palette.common.purple,
          }}
        >
          {params.row.confession ? (
            <DoneAllIcon color="success" />
          ) : (
            <CloseIcon color="error" />
          )}
        </Typography>
      );
    },
  },
];
