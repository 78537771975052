import React, { useState, useEffect } from "react";
import { Toolbar, Typography } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { StyedPageWrapper } from "../../components/shareStyles";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectSingleClient } from "../../features/clients/clientsSlice";
import { selectUserAuth } from "../../features/auth/authSlice";
import { fetchSingleClientAsync } from "../../features/clients/asyncClientsActions";
import { Client } from "../../types/Client";
import {
  StyledInputsWrapper,
  StyledDateWrapper,
  StyledTypography,
  StyledTextField,
  StyledButtonWrapper,
  StyledButton,
} from "./ClientPageStyled";
import CustomInput from "../../components/inputComponent/inputComponent";
import { selectSingleServant } from "../../features/servants/servantsSlice";
import { fetchSignleServantAsync } from "../../features/servants/asyncServantsActions";
import { verifyAllForm } from "../../utility/helpers";

export const ClientPage = (): React.ReactElement => {
  const [client, setClient] = useState<Client>();
  const [showErrors, setShowErrors] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const { id } = useParams();
  const dispatch = useAppDispatch();
  const singleClient = useAppSelector(selectSingleClient);
  const singleServant = useAppSelector(selectSingleServant);
  const userAuth = useAppSelector(selectUserAuth);
  const location = useLocation();

  useEffect(() => {
    if (id && location.state?.isServant) {
      dispatch(
        fetchSignleServantAsync({ id, accessToken: userAuth.accessToken })
      );
    } else if (id) {
      dispatch(
        fetchSingleClientAsync({ id, accessToken: userAuth.accessToken })
      );
    }
  }, [dispatch, id, location, userAuth.accessToken]);

  useEffect(() => {
    if (singleServant?.id) {
      if (client?.id) {
        singleServant.id !== client.id && setClient(singleServant);
      } else {
        setClient(singleServant);
      }
    } else if (singleClient?.id) {
      if (client?.id) {
        singleClient.id !== client.id && setClient(singleClient);
      } else {
        setClient(singleClient);
      }
    }
  }, [client, singleClient, singleServant]);

  const handleInputChange = (label: keyof Client, e: string) => {
    let newState = {} as Client;
    setIsChanged(true);
    setClient((prevState) => {
      if (prevState) {
        return { ...prevState, [label]: e };
      } else {
        return newState;
      }
    });
  };

  const handleSaveValues = () => {
    if (
      verifyAllForm([
        {
          verification: "required",
          value: client?.DOB.toString() as string,
        },
        {
          verification: "required",
          value: client?.phone.toString() as string,
        },
        {
          verification: "required",
          value: client?.educationalLevelOrWork as string,
        },
        {
          verification: "required",
          value: client?.whatsapp as string,
        },
        {
          verification: "required",
          value: client?.fatherOfConfession as string,
        },
        {
          verification: "required",
          value: client?.email as string,
        },
        {
          verification: "required",
          value: client?.church as string,
        },
        {
          verification: "required",
          value: client?.address as string,
        },
        {
          verification: "required",
          value: client?.service as string,
        }
      ])
    ) {
      console.log("handle Submit")
    } else {
      setShowErrors(true);
    }
  };

  return (
    <StyedPageWrapper style={{ direction: 'rtl' }}>
      <Toolbar />
      <Typography variant="h5" fontWeight="bold" sx={{ margin: "2rem 1rem" }}>
        {client?.name ?? ""}
      </Typography>
      <StyledInputsWrapper>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label="تاريخ الميلاد"
            value={client?.DOB ?? new Date()}
            // minDate={moment(client?.DOB)}
            onChange={(newValue) => {
              handleInputChange("DOB", newValue?.toString() as string);
            }}
            renderInput={(params) => {
              return (
                <StyledDateWrapper>
                  <StyledTypography variant="h6">
                    تاريخ الميلاد
                  </StyledTypography>
                  <StyledTextField {...params} fullWidth />
                </StyledDateWrapper>
              );
            }}
          />
        </LocalizationProvider>
        <CustomInput
          labelName="رقم الموبايل"
          inputType="number"
          handleInputChange={(e) => {
            handleInputChange("phone", e);
          }}
          placeholder="لا يوجد"
          value={Number(client?.phone ?? 0)}
          uniqueIdentifier="phone-number-input"
          showErrors={showErrors}
          verification="required"
        />
        <CustomInput
          labelName="المرحلة الدراسية أو العمل"
          inputType="text"
          handleInputChange={(e) =>
            handleInputChange("educationalLevelOrWork", e)
          }
          value={client?.educationalLevelOrWork ?? ""}
          uniqueIdentifier="educ-lovel-or-work"
          placeholder="غير محدد"
          showErrors={showErrors}
          verification="required"
        />
        <CustomInput
          labelName="رقم ال Whatsapp"
          inputType="number"
          handleInputChange={(e) => handleInputChange("whatsapp", e)}
          value={Number(client?.whatsapp ?? "")}
          uniqueIdentifier="whatsapp-number"
          placeholder="لا يوجد"
          showErrors={showErrors}
          verification="required"
        />
        <CustomInput
          labelName="أب الاعتراف"
          inputType="text"
          handleInputChange={(e) => handleInputChange("fatherOfConfession", e)}
          value={client?.fatherOfConfession ?? ""}
          uniqueIdentifier="father-Of-confession"
          placeholder="لا يوجد"
          showErrors={showErrors}
          verification="required"
        />
        <CustomInput
          labelName="البريد الالكترونى"
          inputType="email"
          handleInputChange={(e) => handleInputChange("email", e)}
          value={client?.email ?? ""}
          placeholder="لا يوجد"
          uniqueIdentifier="email"
          showErrors={showErrors}
          verification="required"
        />
        <CustomInput
          labelName="الكنيسة"
          inputType="text"
          handleInputChange={(e) => handleInputChange("church", e)}
          value={client?.church ?? ""}
          uniqueIdentifier="church"
          placeholder="غير محدد"
          showErrors={showErrors}
          verification="required"
        />
        <CustomInput
          labelName="العنوان"
          inputType="text"
          handleInputChange={(e) => handleInputChange("address", e)}
          value={client?.address ?? ""}
          placeholder="غير محدد"
          uniqueIdentifier="address"
          showErrors={showErrors}
          verification="required"
        />
        <CustomInput
          labelName="الخدمة الحالية"
          inputType="text"
          handleInputChange={(e) => handleInputChange("service", e)}
          value={client?.service ?? ""}
          placeholder="لا يوجد"
          uniqueIdentifier="service"
          showErrors={showErrors}
          verification="required"
        />
      </StyledInputsWrapper>
      <StyledButtonWrapper>
        <StyledButton variant="contained" disabled={!isChanged} onClick={handleSaveValues}>
          حفظ التغيرات
        </StyledButton>
      </StyledButtonWrapper>
    </StyedPageWrapper>
  );
};
