import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { SoulNote } from "../../types/SoulNote";
import { fetchSoulnoteAsync } from "./asyncSoulnoteAction";

export interface SoulnoteState {
  value: SoulNote[];
  status: "loading" | "idle" | "failed";
  errorMessage: string;
}

const initialState: SoulnoteState = {
  value: [],
  status: "idle",
  errorMessage: "",
};

export const SoulnoteSlice = createSlice({
  name: "soulnote",
  initialState,
  reducers: {
    handleCloseSoulnoteSnackbar: (state) => {
      state.status = "idle";
      state.errorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSoulnoteAsync.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchSoulnoteAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.value = action.payload;
    });
    builder.addCase(fetchSoulnoteAsync.rejected, (state) => {
      state.status = "failed";
      state.errorMessage = "برجاء المحاولة مرة اخرى";
    });
  },
});

export const { handleCloseSoulnoteSnackbar } = SoulnoteSlice.actions;

export const selectSoulNoteStatus = (state: RootState) => state.soulnote.status;
export const selectSoulNotes = (state: RootState) => state.soulnote.value;
export const selectSoulNoteErrorMessage = (state: RootState) =>
  state.soulnote.errorMessage;

export default SoulnoteSlice.reducer;
