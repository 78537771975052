import { Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import moment from "moment";

export interface AbsenceState {
  absenceDate: number;
}

export const Columns = (): GridColDef[] => ([
    {
        field: "attendanceDate",
        headerName: "التاريخ",
        sortable: false,
        filterable: false,
        width: 1080,
        renderCell: (params: any) => {
          return (
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                color: (theme) => theme.palette.common.purple,
              }}
            >
              {moment(params.row.attendanceDate).format("YYYY / MM / DD")}
            </Typography>
          );
        },
    },
    {
      field: "absence",
      headerName: "الحضور",
      sortable: false,
      filterable: false,
      width: 80,
      renderCell: (params: any) => {
        return (
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
              color: (theme) => theme.palette.common.purple,
            }}
          >
            <DoneAllIcon color="success" />
          </Typography>
        );
      },
  },
])