import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Typography } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import { SvgIcon } from '@mui/material';

export const StyledFormController = styled(FormControl)(({ theme }) => ({
  '& .MuiInputBase-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.common.purple,
      '& .MuiInputLabel-root': {
        color: theme.palette.common.purple,
      },
    },
    '& fieldset': {
      color: theme.palette.common.black,
    },
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiInputBase-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.purple,
      },
    },
  },
}));

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  '&.Mui-focused': {
    color: theme.palette.common.purple,
  },
}));

export const StyledLabelTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '1.2rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
  },
}));

export const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.7)',
}));

export const StyledIconWrapper = ({
  Icon,
  sx,
}: {
  Icon: typeof SvgIcon;
  sx?: { margin?: string; padding?: string };
}): React.ReactElement => {
  const StyledImage = styled(Icon)(({ theme }) => ({
    color: theme.palette.primary.main
  }));
  return <StyledImage sx={sx} />;
};
