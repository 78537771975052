import axios, { Method } from 'axios';

export const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const fetchData = (url: string, token?: string) => {
  return token
    ? API.get(url, {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => res.data)
    : API.get(url, {
        headers: {
          'content-type': 'application/json',
        },
      }).then((res) => res.data);
};

interface ModifyDataProps<T> {
  url: string;
  data: T;
  method?: Method;
  token?: string;
  isFormData?: boolean;
}

export const ModifyData = <T>({
  url,
  data,
  method = 'POST',
  token,
  isFormData = false,
}: ModifyDataProps<T>) => {
  const stringifiedData = data;
  return token
    ? API({
        method,
        url,
        data: stringifiedData,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': `${
            isFormData ? 'multipart/form-data' : 'application/json'
          }`,
        },
      }).then((res) => {
        return res.data;
      })
    : API({
        method,
        url,
        data: stringifiedData,
      }).then((res) => {
        return res.data;
      });
};
