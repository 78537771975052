import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  deleteClientsAsync,
  fetchClientsAsync,
  fetchSingleClientAsync,
} from "./asyncClientsActions";
import { Client } from "../../types/Client";

export interface ClientSliceState {
  value: Client[];
  status: "idle" | "loading" | "failed";
  singleClient: Client;
  errorMessage: string;
  successMessage: string;
}

const initialState: ClientSliceState = {
  value: [],
  status: "idle",
  singleClient: {} as Client,
  errorMessage: "",
  successMessage: ""
};

export const ClientSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    handleCloseClientSnackbar: (state) => {
      state.status = "idle";
      state.errorMessage = "";
      state.successMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClientsAsync.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchClientsAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.value = action.payload;
    });
    builder.addCase(fetchClientsAsync.rejected, (state, action) => {
      state.status = "failed";
      state.errorMessage = "حدث خطأ ما حاول مرة اخرى";
    });
    builder.addCase(fetchSingleClientAsync.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchSingleClientAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.singleClient = action.payload;
    });
    builder.addCase(fetchSingleClientAsync.rejected, (state, action) => {
      state.status = "failed";
      state.errorMessage = "خطأ برجاء المحاولة مرة آخري";
    });
    builder.addCase(deleteClientsAsync.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(deleteClientsAsync.fulfilled, (state) => {
      state.status = 'idle'
      state.successMessage = "Done, user deleted Successfully"
    });
    builder.addCase(deleteClientsAsync.rejected, (state) => {
      state.status = 'failed'
      state.errorMessage = "Error, please try again later"
    })
  },
});

export const { handleCloseClientSnackbar } = ClientSlice.actions;

export const selectClients = (state: RootState) => state.clients.value;
export const selectClientsStatus = (state: RootState) => state.clients.status;
export const selectSingleClient = (state: RootState) =>
  state.clients.singleClient;
export const selectClientsErrorMessage = (state: RootState) =>
  state.clients.errorMessage;
export const selectClientsSuccessMessage = (state: RootState) => state.clients.successMessage;

export default ClientSlice.reducer;
