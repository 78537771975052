import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Client } from "../../types/Client";
import {
  approveClientAsync,
  createServantsAsync,
  deleteServantsAsync,
  fetchSignleServantAsync,
  fetchUsersAsync,
} from "./asyncServantsActions";

export interface ServantsState {
  value: Client[];
  singleServant: Client;
  limit: number;
  page: number;
  pageCount: number;
  totalCount: number;
  status: "idle" | "loading" | "failed" | "success";
  errorMessage: string;
  successMessage: string;
}

const initialState: ServantsState = {
  value: [],
  singleServant: {} as Client,
  status: "idle",
  errorMessage: "",
  successMessage: "",
  limit: 10,
  page: 1,
  pageCount: 0,
  totalCount: 0,
};

export const ServantsSlice = createSlice({
  name: "servants",
  initialState,
  reducers: {
    handleCloseServantSnackbar: (state) => {
      state.status = "idle";
      state.errorMessage = "";
      state.successMessage = "";
    },
    handleSetServantsPages: (state, action) => {
      state.page = action.payload;
    },
    handleDeleteServant: (state, action) => {
      state.value = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createServantsAsync.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(createServantsAsync.fulfilled, (state, action) => {
      state.status = "success";
      state.successMessage = "تمت اضافة الخادم بنجاح";
    });
    builder.addCase(createServantsAsync.rejected, (state) => {
      state.status = "failed";
      state.errorMessage = "خطا، برجاء المحاولة مرة اخرى";
    });
    builder.addCase(fetchUsersAsync.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchUsersAsync.fulfilled, (state, action) => {
      state.status = "success";
      state.value = action.payload.data;
      state.page = action.payload.page;
      state.limit = action.payload.limit;
      state.pageCount = action.payload.pageCount;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(fetchUsersAsync.rejected, (state) => {
      state.status = "failed";
      state.errorMessage = "خطا، برجاء المحاولة مرة اخرى";
    });
    builder.addCase(deleteServantsAsync.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(deleteServantsAsync.fulfilled, (state, action) => {
      state.status = "success";
      state.successMessage = "تم حذف الخادم بنجاح";
    });
    builder.addCase(deleteServantsAsync.rejected, (state) => {
      state.status = "failed";
      state.errorMessage = "خطا، برجاء المحاولة مرة اخرى";
    });
    builder.addCase(fetchSignleServantAsync.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchSignleServantAsync.fulfilled, (state, action) => {
      state.singleServant = action.payload;
      state.status = "idle";
    });
    builder.addCase(fetchSignleServantAsync.rejected, (state) => {
      state.status = "failed";
      state.errorMessage = "خطا، برجاء المحاولة مرة اخرى";
    });
    builder.addCase(approveClientAsync.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(approveClientAsync.fulfilled, (state, action) => {
      state.status = 'success'
      state.successMessage = "تمت اضافة المخدوم بنجاح"
    })
    builder.addCase(approveClientAsync.rejected, state => {
      state.status = 'failed'
      state.errorMessage = "خطا، برجاء المحاولة مرة اخرى"
    })
  },
});

export const {
  handleCloseServantSnackbar,
  handleSetServantsPages,
  handleDeleteServant,
} = ServantsSlice.actions;

export const selectServants = (state: RootState) => state.servants.value;
export const selectServantPageNumber = (state: RootState) =>
  state.servants.page;
export const selectServantsPageCount = (state: RootState) =>
  state.servants.pageCount;
export const selectServantsLimitNumber = (state: RootState) =>
  state.servants.limit;
export const selectServantsTotalCount = (state: RootState) =>
  state.servants.totalCount;
export const selectServantsStatus = (state: RootState) => state.servants.status;
export const selectServantsErrorMessage = (state: RootState) =>
  state.servants.errorMessage;
export const selectServantsSuccessMessage = (state: RootState) =>
  state.servants.successMessage;
export const selectSingleServant = (state: RootState) =>
  state.servants.singleServant;

export default ServantsSlice.reducer;
