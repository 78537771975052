import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Absence } from "../../types/Absence";
import { fetchAbsenseAsync } from "./asyncAbsenceActions";

export interface AbsenceState {
    value: Absence[];
    status: "loading" | "idle" | "failed";
    errorMessage: string;
    limit: number;
    page: number;
    pageCount: number;
    totalCount: number;
};

const initialState: AbsenceState = {
    value: [],
    status: 'idle',
    errorMessage: '',
    limit: 10,
    page: 1,
    pageCount: 1,
    totalCount: 1
} 

export const AbsenceSlice = createSlice({
    name: 'absence',
    initialState,
    reducers: {
        handleCloseAbsenceSnackbar: (state) => {
            state.status = "idle";
            state.errorMessage = "";
          },
          handleSetAbsencePages: (state, action) => {
            state.page = action.payload;
          }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAbsenseAsync.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchAbsenseAsync.fulfilled, (state, action) => {
            const { data, page, limit, pageCount, totalCount } = action.payload;
            state.status = 'idle';
            state.value = data;
            state.limit = limit;
            state.page = page;
            state.pageCount = pageCount;
            state.totalCount = totalCount;
        })
        builder.addCase(fetchAbsenseAsync.rejected, (state) => {
            state.status = 'failed';
            state.errorMessage = 'برجاء المحاولة مرة آخرى'
        })
    }
});

export const { handleCloseAbsenceSnackbar, handleSetAbsencePages } = AbsenceSlice.actions;

export const selectAbsences = (state: RootState) => state.absence.value;
export const selectAbsencePageCount = (state: RootState) => state.absence.pageCount;
export const selectAbsencePage = (state: RootState) => state.absence.page;
export const selectAbsenceTotalCount = (state: RootState) => state.absence.totalCount;
export const selectAbsenceLimit = (state: RootState) => state.absence.limit;
export const selectAbsencesStatus = (state: RootState) => state.absence.status;
export const selectAbsenceErrorMessage = (state: RootState) => state.absence.errorMessage

export default AbsenceSlice.reducer;