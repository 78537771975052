import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledWrapper = styled(Box)(({ theme }) => ({
  height: 320,
  transform: "translateZ(0px)",
  flexGrow: 1,
  position: "absolute",
  bottom: theme.spacing(2),
  left: theme.spacing(10),
}));
