import { GridColDef } from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from "moment";

export interface ColumnProps {
  handleApprove: (id: string) => void;
  handleDelete: (id: string) => void;
}

export const Columns = ({ handleApprove, handleDelete }: ColumnProps): GridColDef[] => [
  {
    field: "name",
    headerName: "الاسم",
    sortable: false,
    filterable: false,
    width: 240,
    renderCell: (params: any) => {
      return (
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
            color: (theme) => theme.palette.common.purple,
          }}
        >
          {params.row.name}
        </Typography>
      );
    },
  },
  {
    field: "DOB",
    sortable: false,
    filterable: false,
    headerName: "تاريخ الميلاد",
    width: 100,
    renderCell: (params: any) => {
      return (
        <Typography variant="body1">
          {moment(params.row.DOB).format("DD-MM-YYYY")}
        </Typography>
      );
    },
  },
  {
    field: "educationalLevelOrWork",
    headerName: "المرحلة الدراسية أو العمل",
    sortable: false,
    filterable: false,
    width: 250,
  },
  {
    field: "verified",
    headerName: "حاله بياناته (الهاتف والبريد الالكتروني)",
    sortable: false,
    filterable: false,
    renderCell: (params: any) => {
      const isAllowed: boolean = params.row.allowed;
      return (
        <Typography
          variant="body1"
          sx={{
            color: isAllowed
              ? (theme) => theme.palette.common.darkGreen
              : (theme) => theme.palette.common.blue,
          }}
        >
          {isAllowed ? "تم التحقق" : "لم يتم التحقق"}
        </Typography>
      );
    },
    width: 230,
  },
  {
    field: "action",
    headerName: "موافق؟",
    sortable: false,
    filterable: false,
    renderCell: (params: any) => {
      return (
        <IconButton color="success" onClick={() => handleApprove(params.row.id)} sx={(theme) => ({
          boxShadow: 3
        })}>
          <CheckCircleIcon />
        </IconButton>
      );
    },
  },
  {
    field: "action2",
    headerName: "غير موافق",
    renderCell: (params: any) => {
      return (
        <IconButton
          color="error"
          onClick={() => handleDelete(params.row.id)}
          sx={(theme) => ({
            boxShadow: 3
          })}
        >
          <DeleteIcon />
        </IconButton>
      );
    },
  },
];
