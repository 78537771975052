import React, { useState, useEffect } from "react";
import { Toolbar } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";

import { StyedPageWrapper } from "../../components/shareStyles";
import { Columns } from "./HomePageColumns";
// import { TableComponent } from "../../components/TableComponent/TableComponent";
import { TableComponent } from "../../components/dataGridComponent/dataGridComponent";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  deleteClientsAsync,
  fetchClientsAsync,
} from "../../features/clients/asyncClientsActions";
import { selectUserAuth } from "../../features/auth/authSlice";
import {
  handleCloseClientSnackbar,
  selectClients,
  selectClientsSuccessMessage,
} from "../../features/clients/clientsSlice";
import {
  StyledPageTitle,
  StyledFilterationContainer,
  StyledFormController,
} from "./HomePageStyles";
import { Client } from "../../types/Client";
import { ExportToExcelButton } from "../../components/exportToExcelButton/ExportToExcelButton";
import { headers } from "./HomePageHeaders";
import { CustomSnackbarComponent } from "../../components/customSnackbar/customSnackbarComponent";

export const HomePageScreen = (): React.ReactElement => {
  const [filter, setFilter] = useState("");
  const [clients, setClients] = useState<Client[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userAuth = useAppSelector(selectUserAuth);
  const clientsData = useAppSelector(selectClients);
  const successMessage = useAppSelector(selectClientsSuccessMessage);

  useEffect(() => {
    if (userAuth?.accessToken) {
      dispatch(fetchClientsAsync({ accessToken: userAuth.accessToken }));
    }
  }, [dispatch, userAuth.accessToken]);

  useEffect(() => {
    setClients(clientsData);
  }, [clientsData]);

  const handleFilter = () => {
    if (filter !== "all") {
      const f = filter === "allowed";
      return clientsData.filter((c) => c.verified === f);
    } else {
      return clientsData;
    }
  };

  useEffect(() => {
    setClients(handleFilter());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleChange = (e: SelectChangeEvent<string>) => {
    setFilter(e.target.value);
  };

  const handleRedirect = (id: string) => {
    navigate(`/client/${id}`);
  };

  const handleDeleteClient = (id: string) => {
    dispatch(deleteClientsAsync({ id, accessToken: userAuth.accessToken }));
    const tempClients = [...clients];

    const userIndex = tempClients.findIndex((c) => c.id === id);
    if (userIndex >= 0) {
      tempClients.splice(userIndex, 1);
    }
    setClients(tempClients);
  };

  return (
    <StyedPageWrapper>
      <Toolbar />
      <ExportToExcelButton
        data={clients?.length ? clients : []}
        headers={headers}
        isFloatingAction
        fileName="Users"
      />
      <CustomSnackbarComponent
        open={successMessage?.length > 0}
        message={successMessage}
        handleClose={() => {
          dispatch(handleCloseClientSnackbar());
        }}
        severity="success"
      />
      <StyledFilterationContainer>
        <StyledPageTitle variant="h5">المستخدمين</StyledPageTitle>
        <StyledFormController sx={{ direction: "ltr" }}>
          <InputLabel id="demo-simple-select-label">حالة المستخدم</InputLabel>
          <Select
            labelId="filter"
            id="filter"
            value={filter}
            label="حالة المستخدم"
            onChange={handleChange}
            sx={(theme) => ({
              width: "20rem",
              color: theme.palette.primary.main,
              [theme.breakpoints.down("md")]: {
                width: "12rem",
              },
            })}
          >
            <MenuItem value={"all"}> الجميع</MenuItem>

            <MenuItem value={"allowed"}> تم تأكيد بياناتة</MenuItem>
            <MenuItem value={"notAllowed"}>مسموح له</MenuItem>
          </Select>
        </StyledFormController>
      </StyledFilterationContainer>
      <TableComponent
        pageCount={1}
        page={1}
        columns={Columns({ handleRedirect, handleDeleteClient })}
        rows={clients?.length ? clients : []}
        rowCount={10}
        onPageChange={(pageNumber) => {}}
        limit={10}
      />
    </StyedPageWrapper>
  );
};
