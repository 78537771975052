import React, { useState, useEffect } from "react";
import { Toolbar } from "@mui/material";
import moment from "moment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Box } from "@mui/material";

import { StyedPageWrapper } from "../../components/shareStyles";
import { LoaderComponent } from "../../components/loaderComponent/loaderComponent";
import {
  StyledFilterationContainer,
  StyledPageTitle,
} from "../HomePage/HomePageStyles";
import {
  StyledTitelsWrapper,
} from "../AbsenseScreen/AbsenceStyles";
import { TableComponent } from "../../components/dataGridComponent/dataGridComponent";
import { Columns } from "./AbsensePerPersonsColumn";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectClients,
  selectClientsStatus,
} from "../../features/clients/clientsSlice";
import { fetchClientsAsync } from "../../features/clients/asyncClientsActions";
import { selectUserAuth } from "../../features/auth/authSlice";
import { fetchAbsenseAsync } from "../../features/attendence/asyncAbsenceActions";
import { selectAbsenceTotalCount, selectAbsences } from "../../features/attendence/absenceSlice";
import { ExportToExcelButton } from "../../components/exportToExcelButton/ExportToExcelButton";
import { headers } from "./AbsensePerPersonHeaders";

export const AbsensePerPerson = (): React.ReactElement => {
  const [selectedClient, setSelectedClient] = useState<string>("");
  const [exportToExcelData, setExportToExcelData] = useState<any>([]);
  const clients = useAppSelector(selectClients);
  const absences = useAppSelector(selectAbsences);
  const { accessToken } = useAppSelector(selectUserAuth);
  const clientsStatus = useAppSelector(selectClientsStatus);
  const limit = useAppSelector(selectAbsenceTotalCount);
  const page = 1;

  const dispatch = useAppDispatch();

  const handleClientSelect = (e: SelectChangeEvent) => {
    setSelectedClient(e.target.value);
  };

  useEffect(() => {
    if (clients.length === 0 && accessToken?.length > 0) {
      dispatch(fetchClientsAsync({ accessToken }));
    }
  }, [accessToken, clients.length, dispatch]);

  useEffect(() => {
    if (selectedClient) {
      dispatch(
        fetchAbsenseAsync({
          limit,
          page,
          userId: selectedClient,
          accessToken,
        })
      );
    }
  }, [accessToken, dispatch, limit, selectedClient]);

  useEffect(() => {
    if (
      exportToExcelData?.length !== absences?.length &&
      absences?.length > 0
    ) {
      const mod = absences.map((a) => ({
        ...a,
        ...a.user,
        absence: "حضر",
        attendanceDate: moment(a.attendanceDate).format("YYYY / MM / DD"),
      }));
      setExportToExcelData(mod);
    }
  }, [absences, exportToExcelData?.length]);

  const getSelectedClientName = (): string => {
    if (!selectedClient) {
      return "";
    }
    const clientIndex = clients.findIndex((c) => c.id === selectedClient);
    // @ts-ignore
    if ((clientIndex) => 0) {
      return clients[clientIndex].name;
    }
    return "";
  };

  return (
    <StyedPageWrapper>
      <Toolbar />
      <LoaderComponent open={clientsStatus === "loading"} />
      <ExportToExcelButton
        headers={headers}
        data={exportToExcelData ?? []}
        isFloatingAction
        fileName={`attendance of ${getSelectedClientName()}`}
      />
      <StyledFilterationContainer
        sx={{
          display: "flex",
          alignItems: "center",
          margin: "1rem",
          justifyContent: "space-between",
        }}
      >
        <StyledTitelsWrapper
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              display: "grid",
              minWidth: "40%",
            },
          })}
        >
          <StyledPageTitle
            variant="h5"
            sx={(theme) => ({
              [theme.breakpoints.down("md")]: {
                fontSize: "1rem",
                margin: 0,
              },
            })}
          >
            {" "}
            غياب:{" "}
          </StyledPageTitle>
          <StyledPageTitle
            variant="h6"
            sx={(theme) =>({
              color:  theme.palette.common.purple,
              padding: "0 1rem",
              [theme.breakpoints.down("md")]: {
                fontSize: "1rem",
                margin: 0,
                padding: 0
              },
            })}
          >
            {" "}
            {getSelectedClientName()}
          </StyledPageTitle>
        </StyledTitelsWrapper>
        <Box>
          <FormControl style={{ width: "20rem" }}>
            <InputLabel id="demo-simple-select-label">الاسم</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedClient}
              label="الاسم"
              onChange={handleClientSelect}
              sx={(theme) => ({
                [theme.breakpoints.down('md')]: {
                  width: '16rem'
                }
              })}
            >
              {clients.map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </StyledFilterationContainer>
      <TableComponent columns={Columns()} rows={absences} />
    </StyedPageWrapper>
  );
};
