// for excel export

export  const headers = [
  {
    label: "الاسم",
    key: "name",
  },
  {
    label: "تاريخ الميلاد",
    key: "DOB",
  },
  {
    label: "المرحلة الدراسية أو العمل",
    key: 'educationalLevelOrWork'
  }, 
  {
    label: "حاله بياناته (الهاتف والبريد الالكتروني)",
    key: "verified"
  }, 
  {
    label: "حاله المستخدم",
    key: 'allowed'
  }
];