import React, { useState, useEffect } from "react";
import { Toolbar } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";

import { StyedPageWrapper } from "../../components/shareStyles";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectUserAuth } from "../../features/auth/authSlice";
import { fetchSoulnoteAsync } from "../../features/soulNote/asyncSoulnoteAction";
import {
  handleCloseSoulnoteSnackbar,
  selectSoulNoteErrorMessage,
  selectSoulNotes,
  selectSoulNoteStatus,
} from "../../features/soulNote/soulNoteSlice";
import { Columns } from "./SoulNotesColumns";
import { TableComponent } from "../../components/dataGridComponent/dataGridComponent";
import {
  StyledFilterationContainer,
  StyledPageTitle,
} from "../HomePage/HomePageStyles";
import { StyledTitelsWrapper } from "./SoulNoteStyles";
import {
  StyledDateWrapper,
  StyledTextField,
  StyledTypography,
} from "./SoulNoteStyles";
import { LoaderComponent } from "../../components/loaderComponent/loaderComponent";
import { CustomSnackbarComponent } from "../../components/customSnackbar/customSnackbarComponent";
import { ExportToExcelButton } from "../../components/exportToExcelButton/ExportToExcelButton";
import { headers } from "./SoulNotesHeaders";

export const SoulNotePage = (): React.ReactElement => {
  const [selectedDate, setSelectedDate] = useState(new Date().valueOf());
  const userAuth = useAppSelector(selectUserAuth);
  const soulNotes = useAppSelector(selectSoulNotes);

  const [exportToExcelData, setExportToExcelData] = useState<any>([]);
  // const exportToExcelData = [
  //   ...soulNotes
  // ]
  const soulNotesStatus = useAppSelector(selectSoulNoteStatus);
  const soulNoteErrorMessage = useAppSelector(selectSoulNoteErrorMessage);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      fetchSoulnoteAsync({
        accessToken: userAuth?.accessToken,
        noteDate: new Date(selectedDate).valueOf(),
      })
    );
  }, [dispatch, selectedDate, userAuth]);

  useEffect(() => {
    if (
      exportToExcelData?.length !== soulNotes?.length &&
      soulNotes?.length > 0
    ) {
      // setExportToExcelData();
      const mod = soulNotes.map((s) => ({ ...s, ...s.user }));
      setExportToExcelData(mod);
    }
  }, [exportToExcelData?.length, soulNotes]);

  return (
    <StyedPageWrapper>
      <Toolbar />
      <ExportToExcelButton
        data={exportToExcelData}
        headers={headers}
        isFloatingAction
        fileName="Soul Note"
      />
      <LoaderComponent open={soulNotesStatus === "loading"} />
      <CustomSnackbarComponent
        open={soulNoteErrorMessage.length > 0}
        handleClose={() => dispatch(handleCloseSoulnoteSnackbar())}
        message={soulNoteErrorMessage}
      />
      <StyledFilterationContainer
        sx={{ display: "flex", alignItems: "center", margin: "1rem" }}
      >
        <StyledTitelsWrapper sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            display: 'grid',
            alignItems: 'flex-end'
          }
        })}>
          <StyledPageTitle
            variant="h5"
            sx={(theme) => ({
              [theme.breakpoints.down("md")]: {
                fontSize: "1rem",
                margin: 0,
                padding: 0,

              },
              textAlign: 'start'
            })}
          >
            النوتة الروحية
          </StyledPageTitle>
          <StyledPageTitle
            variant="h6"
            sx={(theme) => ({
              color: theme.palette.common.purple,
              padding: "0 1rem",
              [theme.breakpoints.down("md")]: {
                fontSize: '0.8rem',
                margin: 0,
                padding: 0,
              },
            })}
          >
            ( {moment(selectedDate).format("YYYY / MM / DD")} )
          </StyledPageTitle>
        </StyledTitelsWrapper>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label="تصفية بالتاريخ"
            value={selectedDate}
            // minDate={moment(client?.DOB)}
            onChange={(newValue) => {
              setSelectedDate(newValue as number);
            }}
            renderInput={(params) => {
              return (
                <StyledDateWrapper
                  sx={(theme) => ({
                    [theme.breakpoints.down("md")]: {
                      width: "15rem",
                    },
                  })}
                >
                  <StyledTypography
                    variant="h6"
                    sx={(theme) => ({
                      [theme.breakpoints.down("md")]: {
                        fontSize: "1rem",
                      },
                    })}
                  >
                    تصفية بالتاريخ
                  </StyledTypography>
                  <StyledTextField {...params} fullWidth disabled />
                </StyledDateWrapper>
              );
            }}
          />
        </LocalizationProvider>
      </StyledFilterationContainer>
      <TableComponent
        columns={Columns}
        rows={soulNotes ?? []}
        pageCount={1}
        page={1}
        rowCount={10}
        onPageChange={(pageNumber) => {}}
        limit={10}
      />
    </StyedPageWrapper>
  );
};
