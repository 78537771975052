import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { SuperAdmin } from "../../types/AdminUser";
import { loginAsync } from "./asyncAuthActions";

export interface AuthState {
  value: { accessToken: string; user: SuperAdmin };
  status: "loading" | "idle" | "failed";
  errorMessage: string;
}

const initialState: AuthState = {
  value:
    (typeof window !== "undefined" &&
      JSON.parse(sessionStorage.getItem("currentUser") as string)) ||
    {},
  status: "idle",
  errorMessage: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    handleCloseAuthSnackbar: (state) => {
      state.status = "idle";
      state.errorMessage = "";
    },
    signout: (state) => {
      //@ts-ignore
      state.value = {};
      sessionStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginAsync.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(loginAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.value = action.payload;
      if (JSON.parse(sessionStorage.getItem("currentUser") as string)) {
        sessionStorage.removeItem("currentUser");
      }
      sessionStorage.setItem("currentUser", JSON.stringify(action.payload));
    });
    builder.addCase(loginAsync.rejected, (state, action) => {
      state.status = "failed";
      state.errorMessage = "يوجد خطأ فى البريد الالكترونى او كلمه السر";
    });
  },
});

export const { signout, handleCloseAuthSnackbar } = authSlice.actions;

export const selectUserAuth = (state: RootState) => state.auth.value;
export const selectUserAuthStatus = (state: RootState) => state.auth.status;
export const selectUserAuthErrorMessage = (state: RootState) =>
  state.auth.errorMessage;

export default authSlice.reducer;
