import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { StyledDataGrid } from "./dataGridComponentStyles";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

export interface TableComponentProps {
  columns: GridColDef[];
  rows?: any;
  rowCount?: number;
  page?: number;
  limit?: number;
  pageCount?: number;
  onPageChange?: (pageNumber: number) => void;
  isWithPagination?: boolean;
}

export const TableComponent = ({
  columns,
  rows = [],
  rowCount = 10,
  limit = 10,
  page=1,
  pageCount=10,
  onPageChange,
  isWithPagination = false,
}: TableComponentProps): React.ReactElement => {
  return (
    <div style={{ height: '60vh', width: "100%", direction: "rtl" }}>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        pageSize={isWithPagination? limit: 100}
        rowsPerPageOptions={ isWithPagination? [limit]: [100000000]}
        disableSelectionOnClick
        paginationMode="server"
        rowCount={isWithPagination? rowCount: 1000000}
        hideFooterPagination={true}
      />
      {isWithPagination && (
        <Stack spacing={2} sx={{ direction: "ltr", marginTop: "1rem" }}>
          <Pagination
            count={pageCount}
            color="secondary"
            page={page}
            onChange={(e, value) => {
              e.preventDefault();
              if(onPageChange)
              onPageChange(value);
            }}
          />
        </Stack>
      )}
    </div>
  );
};
